


































































































.content-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.content-box {
  width: 400px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h1 {
  text-align: center;
}

.fullwidth-button {
  width: 100%;
}

.links-container {
  display: flex;
  justify-content: center;
}
